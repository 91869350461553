import { createAction, props } from '@ngrx/store';

export enum AuthActionsTypes {
    loginUser = '[Auth] Login User',
    successLoginUser = '[Auth] Success Login User',
    errorLoginUser = '[Auth] Error Login User',
    resetLogged = '[Auth] Reset logged'
}

// LOGIN
export const loginUser = createAction(
    AuthActionsTypes.loginUser,
    props<{ userName: string, password: string , remember: boolean}>()
);
export const successloginUser = createAction(
    AuthActionsTypes.successLoginUser,
    props<{ logged: boolean }>()
);
export const errorLoginUser = createAction(
    AuthActionsTypes.errorLoginUser,
    props<{ logged: boolean }>()
);

export const resetLogged = createAction(AuthActionsTypes.resetLogged);
