import { createAction, props } from "@ngrx/store";
import { ForgotPassword } from "@modules/auth/interfaces/forgot_password.interface";

export enum ForgotpasswordActionsTypes {
    sendCode = '[Forgotpassword] Send Code',
    successSendCode = '[Forgotpassword] Success Send Code',
    errorSendCode = '[Forgotpassword] Error Send Code',
    verifyCode = '[Forgotpassword] Verify Code',
    sucessVerifyCode = '[Forgotpassword]Success Verify Code',
    errorVerifyCode = '[Forgotpassword] Error Verify Code',
    ChangedPassword = '[ChangedPassword] Changed Password',
    successChangedPassword = '[ChangedPassword] Changed Password success',
    errorChangedPassword = '[ChangedPassword] Changed Password error',
    resetForgot = '[ChangedPassword] reset Forgot'
}

// SEND CODE
export const sendCode = createAction(
    ForgotpasswordActionsTypes.sendCode,
    props<ForgotPassword>()
);
export const successSendCode = createAction(ForgotpasswordActionsTypes.successSendCode);
export const errorSendCode = createAction(ForgotpasswordActionsTypes.errorSendCode, props<{ payload: any }>());

// VERIFY CODE
export const verifyCode = createAction(
    ForgotpasswordActionsTypes.verifyCode,
    props<{ code: string, email: string }>()
);
export const sucessVerifyCode = createAction(ForgotpasswordActionsTypes.sucessVerifyCode, props<{ recovered: boolean }>());
export const errorVerifyCode = createAction(ForgotpasswordActionsTypes.errorVerifyCode, props<{ recovered: boolean }>())

// CHANGE PASSWORD
export const ChangedPassword = createAction(
    ForgotpasswordActionsTypes.ChangedPassword,
    props<{ email: string, newPassword: string }>()
);
export const successChangedPassword = createAction(ForgotpasswordActionsTypes.successChangedPassword);
export const errorChangedPassword = createAction(ForgotpasswordActionsTypes.errorChangedPassword, props<{ payload: any }>())

// RESET FORGOT FLAG
export const resetForgot = createAction(ForgotpasswordActionsTypes.resetForgot);

